<template>
  <div>
    <div class="row">
      <div class="col-lg-12">
        <div class="card-placeholder">
          <div class="card-header">
            <vb-headers-heading-2
              :data="{
                title: 'Create Installment Plan',
                  description:
                    'Create and digitize your installment plans or scheduled payments for real estate, car loans, school fees, electronics and any other scheduled payments',
              }"
            />
          </div>
        </div>
        <div class="card card-top card-top-primary">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Payment Plan - Order & Item' }" />
          </div>
          <div class="card-body"><vb-forms-2 /></div>
        </div>
        <div class="card">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Payment Plan Type' }" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Installments' }" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Installment Group 1' }" />
          </div>
        </div>
        <div class="card">
          <div class="card-header">
            <vb-headers-card-header :data="{ title: 'Installment Group 2' }" />
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VbHeadersHeading2 from '@/@vb/widgets/Headers/Heading2'
import VbHeadersCardHeader from '@/@vb/widgets/Headers/CardHeader'
import VbForms2 from '@/@vb/widgets/Forms/2'

export default {
  name: 'VbCreateNewInstallmentPlan',
  components: {
    VbHeadersHeading2,
    VbHeadersCardHeader,
    VbForms2,
  },
}
</script>
